import { dynamicsObject } from "@/interfaces";
import axios from "axios";

export default {
  requestMethod: async () => {
    return await axios.get("/api/authentication");
  },
  getReferralMethod: async () => {
    return await axios.get("/api/user/referral");
  },
  getPromocodeMethod: async () => {
    return await axios.get("/api/user/promocode");
  },
  getFinanceMethod: async () => {
    return await axios.get("/api/user/finance");
  },
  createPaymentMethod: async (data: { amount: number }) => {
    return await axios.post("/api/user/payment", data);
  },
  updateMethod: async (data: { name: string, email: string, city: string, phone: string }) => {
    return await axios.patch("/api/user", data);
  },
  updateFinanceMethod: async (data: { INN: number | null, BIK: number | null, account: number | null, type: string, address: string }) => {
    return await axios.patch("/api/user/finance", data);
  },
  updatePartnerMethod: async () => {
    return await axios.patch("/api/user/partner");
  },
  updatePartnerTestMethod: async (answers: Array<dynamicsObject>) => {
    return await axios.patch("/api/user/partner/test", { answers });
  },
  updatePasswordMethod: async (data: { old_password: string, new_password: string, repeat_password: string }) => {
    return await axios.patch("/api/user/password/change", data);
  },
  applicationPartner: async () => {
    return await axios.patch("/api/user/partner/application");
  },
};
