import { dynamicsObject } from "@/interfaces"
import { Modal } from "@/interfaces/modal/modal.dto"

const types = [
  { _id: 'Юридическое лицо', name: 'Юридическое лицо' },
  { _id: 'Самозанятый', name: 'Самозанятый' },
]

export const partner = (data: dynamicsObject): Array<Modal.Module> => {
  return [
    {
      id: 'admin-partner-edit',
      data: {
        _id: data?._id,
        name: data?.name,
        email: data?.email,
        verification: data?.verification,

        finance_id: data?.finance?._id,
        finance_name: data?.finance?.name,
        finance_type: data?.finance?.type,
        finance_INN: data?.finance?.INN,
        finance_BIK: data?.finance?.BIK,
        finance_account: data?.finance?.account,
        finance_address: data?.finance?.address,
      },
      inputs: [
        { required: true, name: 'ФИО *', placeholder: 'ФИО', id: 'name', grid: '1 / 11', type: 'text', drop: false, showDrop: false, drop_data: [], show: true },
        { required: true, name: 'Почта *', placeholder: 'Почта', id: 'email', grid: '1 / 11', type: 'text', drop: false, showDrop: false, drop_data: [], show: true },
        { required: false, name: 'Верификация', placeholder: '', id: 'verification', grid: '1 / 11', type: 'checkbox', drop: false, showDrop: false, drop_data: [], show: true },

        { required: false, disabled: true, show: true, id: 'finance_type', name: 'Юридическое лицо или самозанятый', placeholder: 'Выберите тип', type: 'text', grid: '1 / 6', drop: true, showDrop: false, drop_data: types },
        { required: false, disabled: true, show: true, id: 'finance_name', name: 'Название компании', placeholder: 'Введите название', type: 'text', grid: '6 / 11', drop: false, showDrop: false, drop_data: []},
        { required: false, disabled: true, show: true, id: 'finance_INN', name: 'ИНН', placeholder: 'Введите ИНН', type: 'text', grid: '1 / 6', drop: false, showDrop: false, drop_data: []},
        { required: false, disabled: true, show: true, id: 'finance_BIK', name: 'БИК банка', placeholder: 'Введите БИК банка', type: 'text', grid: '6 / 11', drop: false, showDrop: false, drop_data: []},
        { required: false, disabled: true, show: true, id: 'finance_account', name: 'Номер счета', placeholder: 'Введите номер счета', type: 'text', grid: '1 / 6', drop: false, showDrop: false, drop_data: []},
        { required: false, disabled: true, show: true, id: 'finance_address', name: 'Адрес', placeholder: 'Введите адрес', type: 'text', grid: '6 / 11', drop: false, showDrop: false, drop_data: []},
      ]
    },
  ]
}