import { Modal } from "@/interfaces/modal/modal.dto";

export const partner: Array<Modal.Content> = [
  {
    id: 'admin-partner-edit',
    title: 'Редактировать партнерa',
    action: 'edit',
    request: '/api/user/admin',
    method: 'patch',
    emit: 'admin-partner-edit',
    cancelName: 'Отмена',
    submitName: 'Сохранить'
  },
];