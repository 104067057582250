import { dynamicsObject } from "@/interfaces";
import { catalog } from "./catalog";
import { store } from "./store";
import { banner } from "./banner";
import { common } from "./common";
import { staff } from "./staff";
import { order } from "./order";
import { partner } from "./partner";

export default ({ id, data }: { id: string, data: dynamicsObject }) => {
  const inputs = [
    ...banner(data),
    ...store(data),
    ...catalog(data),
    ...common(data),
    ...staff(data),
    ...order(data),
    ...partner(data),
  ]
  return inputs.find(input => input.id === id) || inputs[0];
}